const http = require('@/middleware/axios').default

export default {
  doGetAll () {
    return new Promise((resolve, reject) => {
      http.get('/course').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doGetAllPublic () {
    return new Promise((resolve, reject) => {
      http.get('/course/available').then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doRead (id) {
    return new Promise((resolve, reject) => {
      http.get(`/course/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doCreate (name, subtitle, content, organizationId, images, cents, queueMinimum = null) {
    return new Promise((resolve, reject) => {
      const course = {
        name: name,
        subtitle: subtitle,
        content: content,
        organizationId: organizationId,
        images: images,
        cents: cents
      }

      if (queueMinimum !== null) {
        course.queueRequired = true
        course.queueMinimum = queueMinimum
      }

      http.post('/course/', course).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doUpdate (id, name, subtitle, content, organizationId, images, cents, queueMinimum = null) {
    return new Promise((resolve, reject) => {
      const course = {
        name: name,
        subtitle: subtitle,
        content: content,
        organizationId: organizationId,
        images: images,
        cents: cents
      }

      if (queueMinimum !== null) {
        course.queueRequired = true
        course.queueMinimum = queueMinimum
      } else {
        course.queueRequired = false
        course.queueMinimum = null
      }

      http.put(`/course/${id}`, course).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doDeactivate (id) {
    return new Promise((resolve, reject) => {
      http.post(`/course/deactivate/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doActivate (id) {
    return new Promise((resolve, reject) => {
      http.post(`/course/activate/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doHidePublicly (id) {
    return new Promise((resolve, reject) => {
      http.post(`/course/private/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doShowPublicly (id) {
    return new Promise((resolve, reject) => {
      http.post(`/course/public/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  doDestroy (id) {
    return new Promise((resolve, reject) => {
      http.delete(`/course/${id}`).then(data => {
        resolve(data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  event: {
    doCreate (groupUuid, courseId, courseEventLocationId, startDateTime) {
      return new Promise((resolve, reject) => {
        http.post('/course/event', {
          groupUuid: groupUuid,
          courseId: courseId,
          courseEventLocationId: courseEventLocationId,
          start: startDateTime
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doReadById (id) {
      return new Promise((resolve, reject) => {
        http.get(`/course/event/id/${id}`).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doReadByCourseId (courseId) {
      return new Promise((resolve, reject) => {
        http.get(`/course/event/courseId/${courseId}`).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doReadByDate (date) {
      return new Promise((resolve, reject) => {
        http.get('/course/event/date', {
          params: {
            date: date
          }
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doReadByDateRange (startDate, endDate) {
      return new Promise((resolve, reject) => {
        http.get('/course/event/dateRange', {
          params: {
            startDate: startDate,
            endDate: endDate
          }
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doReadFromCourseIdAndGatherGroups (courseId, minDate) {
      return new Promise((resolve, reject) => {
        http.get(`/course/event/courseId/${courseId}/grouped`, {
          params: {
            minDate: minDate
          }
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doReadByGroupUuid (groupUuid) {
      return new Promise((resolve, reject) => {
        http.get(`/course/event/groupUuid/${groupUuid}`).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doUpdateById (id, groupUuid, courseEventLocationId, start) {
      return new Promise((resolve, reject) => {
        const courseEvent = {
          groupUuid: groupUuid,
          courseEventLocationId: courseEventLocationId,
          start: start
        }

        http.put(`/course/event/${id}`, courseEvent).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doDestroy (id) {
      return new Promise((resolve, reject) => {
        http.delete(`/course/event/${id}`).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    location: {
      doReadById (id) {
        return new Promise((resolve, reject) => {
          http.get(`/course/event/location/id/${id}`).then(data => {
            resolve(data.data)
          }).catch(error => {
            reject(error)
          })
        })
      },
      doReadAll () {
        return new Promise((resolve, reject) => {
          http.get('/course/event/location/all').then(data => {
            resolve(data.data)
          }).catch(error => {
            reject(error)
          })
        })
      }
    }
  },
  organization: {
    doReadAll () {
      return new Promise((resolve, reject) => {
        http.get('/course/organization/all').then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
