import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faCcStripe, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally

library.add(fas) // Include needed icons
library.add(faFacebook) // Include needed icons
library.add(faInstagram) // Include needed icons
library.add(faCcStripe)

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    dark: true,
    themes: {
      dark: {
        primary: '#0F75BC',
        secondary: '#87BADD',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#2ecc71',
        warning: '#e67e22'
      },
      light: {
        primary: '#0F75BC',
        secondary: '#87BADD',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#2ecc71',
        warning: '#e67e22'
      }
    }
  },
  icons: {
    iconfont: 'faSvg'
  }
})
