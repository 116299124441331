import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import VueMeta from 'vue-meta'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'tiptap-vuetify/dist/main.css'
import VideoBackground from 'vue-responsive-video-background-player'
import VueSnip from 'vue-snip'
import VueTheMask from 'vue-the-mask'
import VueApexCharts from 'vue-apexcharts'
import { StripePlugin } from '@vue-stripe/vue-stripe'

const vueConfig = require('vue-config')
const config = require('@/content.config.json')

Vue.config.productionTip = false

Vue.prototype.$circleType = require('circletype')
Vue.prototype.$store = require('@/store/index').default
Vue.prototype.$http = require('@/middleware/axios').default
Vue.prototype.$services = require('@/services/index').default
Vue.prototype.$moment = require('moment')
Vue.prototype.$uuid = require('uuid')

Vue.component('video-background', VideoBackground)

Vue.use(vueConfig, config)
Vue.use(VueMeta)
Vue.use(vuetify)
Vue.use(VueSnip)
Vue.use(VueTheMask)
Vue.use(VueApexCharts)

const stripePublishKey = 'pk_test_51M6RQ1ExCJsTpIHVppnbpsrDGXRcZY2hJBJl3xmo3IQ1QOP3lfHe6F111Jq8p1d49if9BPcwXvbSpZ22DHsRS98400RSwIYTDX'

Vue.use(StripePlugin, {
  pk: stripePublishKey,
  testMode: true
})

Vue.mixin({
  data: () => ({
    applicationVersion: '1.0.0',
    darkThemeActive: false,
    stripeApiAccount: {
      pk: stripePublishKey
    },
    inputs: {
      rules: {
        simplyRequired: [
          value => !!value || 'Required.'
        ],
        simplyRequiredNumbers: [
          value => !!value || 'Required.',
          value => {
            const pattern = /^\d*\.?\d*$/
            return pattern.test(value) || 'Decimal numbers only'
          }
        ],
        passwordRequired: [
          value => !!value || 'Required.',
          value => (value || '').length >= 8 || '8 Min Characters',
          value => (value || '').length <= 32 || '32 Max characters'
        ],
        emailRequired: [
          value => !!value || 'Required.',
          value => (value || '').length <= 320 || '320 Max characters',
          value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        ],
        phoneNumberRequired: [
          value => !!value || 'Required.',
          value => (value || '').length <= 14 || '14 Max characters',
          value => {
            const pattern = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/
            return pattern.test(value) || 'Invalid Phone Number.'
          }
        ]
      }
    }
  }),
  methods: {
    doScrollToTop () {
      this.$vuetify.goTo(0, {
        duration: 0,
        offset: 0,
        easing: 'easeInOutCubic'
      })
    },
    doGenerateCourse (courseName, courseDescription, organizationName, organizationUrl) {
      return {
        '@context': 'https://schema.org',
        '@type': 'Course',
        name: courseName,
        description: courseDescription,
        provider: {
          '@type': 'Organization',
          name: organizationName,
          sameAs: organizationUrl
        }
      }
    },
    turnOnDarkMode () {
      this.$vuetify.theme.dark = true
    },
    turnOnLightMode () {
      this.$vuetify.theme.dark = false
    },
    doOpenExternalUrl (url) {
      window.open(url, '_blank')
    },
    doSetDocumentTitle (title, includeCompany = false) {
      const company = 'Sea2Sea Scuba'

      if (includeCompany) {
        title = `${company} | ${title}`
      }

      document.title = title
    },
    doCallBusiness () {
      this.doOpenExternalUrl('tel:+14804485636')
    },
    formatPhoneNumber (phoneNumberString) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return null
    }
  },
  mounted () {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.darkThemeActive = true
      this.turnOnDarkMode()
    } else {
      this.darkThemeActive = false
      this.turnOnLightMode()
    }
  }
})

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'fa'
})

new Vue({
  router,
  store,
  vuetify,
  metaInfo: () => ({
    title: config.title,
    htmlAttrs: {
      lang: 'en'
    },
    meta: [
      { name: 'description', content: config.description }
    ]
  }),
  render: h => h(App)
}).$mount('#app')
