<template>
  <v-app id="inspire">
    <v-app-bar app v-if="showNavbar" class="hideOnPrinter">

      <v-container class="pt-1 fill-height hidden-sm-and-down">
        <div style="width: 100%;">
          <v-avatar class="mr-4" width="100" tile>
            <v-img :max-width="100" class="show-pointer" src="https://s3.us-east-1.wasabisys.com/sea2sea/media/Logo-White-Text.png" @click="$router.replace({ name: 'home' })"></v-img>
          </v-avatar>

          <v-btn v-for="route in navigation" :key="route.name" :to="{ path: route.path }" text replace class="mr-2">
            <span>{{ route.name }}</span>
          </v-btn>

          <div class="mt-2" style="float: right;">
            <v-btn v-if="$store.state.user.id !== null" class="primary mx-2" :to="{ name: 'admin' }">
              <font-awesome-icon size="md" :icon="['fa', 'id-badge']"></font-awesome-icon>
              <span class="ml-2 hidden-md-and-down">Admin</span>
            </v-btn>

            <v-btn class="primary mx-2" :to="{ name: 'checkout-cart' }">
              <font-awesome-icon size="1x" :icon="['fa', 'cart-shopping']"></font-awesome-icon>
              <span class="ml-2 hidden-md-and-down">My cart</span>
              <span class="ml-2" v-if="cartTotal >= 1">({{ cartTotal }})</span>
            </v-btn>
          </div>

        </div>
      </v-container>

      <div class="hidden-md-and-up" style="width: 100%;">
        <v-row>
          <v-col sm="2">
            <v-btn icon color="primary" @click="mobile = true">
              <v-icon small>fa-bars</v-icon>
            </v-btn>
          </v-col>
          <v-col offset-sm="8" sm="2">
            <div class="text-right">
              <v-btn icon color="primary" :to="{ name: 'checkout-cart' }" :#exact-active-class="null">
                <v-badge
                  :content="cartTotal"
                  :value="cartTotal"
                  color="red"
                  overlap
                  class="ma-0 pa-0"
                >
                  <font-awesome-icon size="1x" class="ma-2" :icon="['fa', 'cart-shopping']"></font-awesome-icon>
                </v-badge>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app-bar>

    <v-sheet>
      <v-navigation-drawer v-model="mobile" fixed temporary>
        <div class="text-center">
          <v-img class="ma-6" src="https://s3.us-east-1.wasabisys.com/sea2sea/media/Logo-White-Text.png" @click="$router.replace({ name: 'home' })"></v-img>
        </div>
        <v-divider></v-divider>
        <div class="my-4">
          <v-list-item :to="{ name: 'home' }" link>
            <div class="pa-2">
              <font-awesome-icon size="sm" :icon="['fas', 'house']" fixed-width></font-awesome-icon>
              <span class="ml-2 text-h6">WELCOME</span>
            </div>
          </v-list-item>
          <v-list-item v-for="route in navigation" :key="route.path" :to="{ path: route.path }" link>
            <div class="pa-2">
              <font-awesome-icon size="sm" :icon="['fas', route.meta.icon]" fixed-width></font-awesome-icon>
              <span class="ml-2 text-h6">{{ route.name.toString().toUpperCase() }}</span>
            </div>
          </v-list-item>
        </div>
        <v-divider></v-divider>
        <div class="text-center mt-12">
          <div>
            <v-btn width="215px" outlined :to="{ name: 'checkout-cart' }">
              <font-awesome-icon size="1x" :icon="['fa', 'cart-shopping']"></font-awesome-icon>
              <span class="ml-2">My cart</span>
              <span class="ml-2" v-if="cartTotal >= 1">({{ cartTotal }})</span>
            </v-btn>
          </div>
          <div class="mt-5">
            <v-btn outlined width="215px" @click="doCallBusiness">
              <font-awesome-icon size="1x" :icon="['fa', 'phone']"></font-awesome-icon>
              <span class="ml-2">Give us a call</span>
            </v-btn>
          </div>
        </div>
      </v-navigation-drawer>
    </v-sheet>

    <v-main class="printLightTheme">
      <router-view/>
    </v-main>

    <div class="primary white--text hideOnPrinter" v-if="showNavbar">
      <v-container class="text-center">
        <div class="pa-4">
          <v-row>
            <v-col>
              <v-row>
                <v-col class="text-center">
                  <div>3217 E Shea Blvd. Ste 5</div>
                  <div>Phoenix, AZ 85028</div>
                </v-col>
                <v-col class="text-center">
                  <v-chip :color="storeOpen ? 'success' : 'error'" dark>
                    We're currently {{ storeOpen ? 'Open' : 'Closed' }}
                  </v-chip>
                </v-col>
              </v-row>

              <v-row no-gutters class="mt-4">
                <v-col>
                  <span class="font-weight-bold">Store Hours</span>

                  <v-simple-table dense class="my-4 primary white--text">
                    <template v-slot:default>
                      <tbody>
                      <tr>
                        <td>Sunday</td>
                        <td class="text-center">12PM - 5PM</td>
                      </tr>
                      <tr>
                        <td>Monday to Friday</td>
                        <td class="text-center">11AM - 7PM</td>
                      </tr>
                      <tr>
                        <td>Saturday</td>
                        <td class="text-center">9AM - 6PM</td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <div class="mb-2">
                <v-btn outlined width="300" @click="doCallBusiness" class="white--text">
                  <font-awesome-icon size="1x" :icon="['fa', 'phone']"></font-awesome-icon>
                  <span class="ml-2">(480) 448-5636</span>
                </v-btn>
              </div>
              <div class="mb-2">
                <v-btn outlined width="300" @click="doOpenExternalUrl('mailto:retail@sea2seascuba.com')" class="white--text">
                  <font-awesome-icon size="1x" :icon="['fa', 'envelope']"></font-awesome-icon>
                  <span class="ml-2">retail@sea2seascuba.com</span>
                </v-btn>
              </div>
              <div class="mb-2">
                <v-btn outlined width="300" @click="doOpenExternalUrl('mailto:info@sea2seascuba.com')" class="white--text">
                  <font-awesome-icon size="1x" :icon="['fa', 'envelope']"></font-awesome-icon>
                  <span class="ml-2">info@sea2seascuba.com</span>
                </v-btn>
              </div>
              <div>
                <v-btn fab color="#3b5998" class="ma-4 white--text" @click="doOpenExternalUrl('https://www.facebook.com/Sea2SeaScuba/')">
                  <font-awesome-icon size="2x" :icon="['fab', 'facebook']"></font-awesome-icon>
                </v-btn>
                <v-btn fab color="#fb3958" class="ma-4 white--text" @click="doOpenExternalUrl('https://www.instagram.com/sea2seascuba/')">
                  <font-awesome-icon size="2x" :icon="['fab', 'instagram']"></font-awesome-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
        <div class="pt-2">
          <div>
            <span style="display: inline-block; vertical-align: middle;">
              Site created by
            </span>
            <span @click="doOpenExternalUrl('https://unixity.co?tracking=sea2sea')" style="cursor: pointer; display: inline-block; vertical-align: middle;" class="mt-3">
              <unixity-logo-text :width="100" :height="70"></unixity-logo-text>
            </span>
            <span style="display: inline-block; vertical-align: middle;">
              for the Love of Diving
            </span>
          </div>
        </div>
        <div class="pt-2">
          Copyright © {{ new Date().getFullYear() }} - <strong>Sea 2 Sea Scuba, LLC.</strong> All Rights Reserved.
        </div>
      </v-container>
    </div>
    <DialogAlert></DialogAlert>
    <cookies-reminder ref="privacy"></cookies-reminder>
  </v-app>
</template>

<script>
import DialogAlert from '@/components/etc/dialogAlert.vue'
import UnixityLogoText from '@/components/unixityLogoText.vue'
import CookiesReminder from '@/components/etc/cookiesReminder.vue'

export default {
  components: { CookiesReminder, UnixityLogoText, DialogAlert },
  data: () => ({
    navigation: [],
    mobile: false,
    storeOpen: false
  }),
  methods: {
    currentlyOpen () {
      const currentTime = new Date(new Date().toLocaleString('en-US', { timeZone: 'America/Phoenix' }))
      const currentDay = currentTime.getDay()
      const currentHours = currentTime.getHours()

      switch (currentDay) {
        case 0: // Sunday
          if (currentHours >= 12 && currentHours < 17) {
            this.storeOpen = true
          }
          break
        case 6: // Saturday
          if (currentHours >= 9 && currentHours < 18) {
            this.storeOpen = true
          }
          break
        default: // Mon-Fri
          if (currentHours >= 11 && currentHours < 19) {
            this.storeOpen = true
          }
          break
      }
    },
    doOpenExternalUrl (url) {
      window.open(url, '_blank')
    },
    doActivateSession () {
      new Promise((resolve, reject) => {
        if (this.$store.getters.doGetSession === null) {
          this.$store.dispatch('doCreateSession').then(session => {
            this.$refs.privacy.doNotify()
            resolve(session)
          }).catch(error => {
            reject(error)
          })
        } else {
          this.$services.session.doGather(this.$store.getters.doGetSession).then(session => {
            resolve(session)
          }).catch(error => {
            reject(error)
          })
        }
      }).then(_ => {

      }).catch(error => {
        this.$root.$emit('showError', error.message)
      })
    },
    doActivateKonami () {
      var allowedKeys = {
        37: 'left',
        38: 'up',
        39: 'right',
        40: 'down',
        65: 'a',
        66: 'b'
      }

      // the 'official' Konami Code sequence
      var konamiCode = ['up', 'up', 'down', 'down', 'left', 'right', 'left', 'right', 'b', 'a']

      // a variable to remember the 'position' the user has reached so far.
      var konamiCodePosition = 0

      // add keydown event listener
      document.addEventListener('keydown', function (e) {
        // get the value of the key code from the key map
        var key = allowedKeys[e.keyCode]
        // get the value of the required key from the konami code
        var requiredKey = konamiCode[konamiCodePosition]

        // compare the key with the required key
        if (key === requiredKey) {
          // move to the next key in the konami code sequence
          konamiCodePosition++

          // if the last key is reached, activate cheats
          if (konamiCodePosition === konamiCode.length) {
            alert('You found the secret page!')
            window.location.href = 'thankyou-message'
            konamiCodePosition = 0
          }
        } else {
          konamiCodePosition = 0
        }
      })
    }
  },
  mounted () {
    this.navigation = this.$router.getRoutes().filter(route => route.meta.showOnNavbar !== undefined ? route.meta.showOnNavbar : false)

    const electron = !(navigator.userAgent.toLowerCase().indexOf(' electron/') === -1)

    if (electron) {
      this.$router.replace({ name: 'admin' })
    }

    this.currentlyOpen()

    this.doActivateKonami()

    this.doActivateSession()
  },
  computed: {
    showNavbar () {
      return this.$route.meta.showNavbar
    },
    cartTotal () {
      let cartTotal = 0

      this.$store.state.cart.groups.forEach(group => {
        if (group.type === 'trip') {
          cartTotal = cartTotal + 1
        } else if (group.type === 'course') {
          cartTotal = cartTotal + group.products.length
        }
      })

      return cartTotal
    }
  }
}
</script>

<style lang="scss">
@import '@/stylesheet/index.scss';

@media print {
  .hideOnPrinter {
    display: none;
    visibility: hidden;
  }
  .showOnPrinterOnly {
    display: initial !important;
  }
  .printLightTheme * {
    background: white !important;
    color: black !important;
    padding: 1px;
  }
}

.showOnPrinterOnly {
  display: none;
}
</style>
