const routes = {
  name: 'admin',
  path: '/admin',
  component: () => import('../views/admin'),
  redirect: { name: 'admin-home' },
  meta: {
    requiresAuth: true,
    requiredRoles: ['admin', 'manager', 'employee']
  },
  children: [
    {
      name: 'admin-home',
      path: 'home',
      component: () => import('../views/admin/Home'),
      meta: {
        icon: 'gauge',
        requiredRoles: ['admin', 'manager', 'employee'],
        adminNavbar: true,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Dashboard'
      }
    },
    {
      name: 'admin-transactions',
      path: 'transaction',
      component: () => import('../views/admin/transactions/index.vue'),
      meta: {
        icon: 'receipt',
        requiredRoles: ['admin', 'manager', 'employee'],
        adminNavbar: true,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Transactions'
      }
    },
    {
      name: 'admin-view-transactions',
      path: 'transaction/:uuid',
      component: () => import('../views/admin/transactions/viewTransaction.vue'),
      meta: {
        icon: 'receipt',
        requiredRoles: ['admin', 'manager', 'employee'],
        adminNavbar: false,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'View Transaction'
      }
    },
    {
      name: 'admin-calendar',
      path: 'calendar',
      component: () => import('../views/admin/schedule'),
      meta: {
        icon: 'calendar-days',
        requiredRoles: ['admin', 'manager', 'employee'],
        adminNavbar: true,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Schedule'
      }
    },
    {
      name: 'admin-courses',
      path: 'courses',
      component: () => import('../views/admin/courses'),
      meta: {
        icon: 'person-swimming',
        requiredRoles: ['admin', 'manager', 'employee'],
        adminNavbar: true,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Courses'
      }
    },
    {
      name: 'admin-trip',
      path: 'trips',
      component: () => import('../views/admin/trips/index.vue'),
      meta: {
        icon: 'plane',
        requiredRoles: ['admin', 'manager', 'employee'],
        adminNavbar: true,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Trips'
      }
    },
    {
      name: 'admin-students',
      path: 'students',
      component: () => import('../views/admin/students'),
      meta: {
        icon: 'fa-message',
        requiredRoles: ['admin', 'manager', 'employee'],
        adminNavbar: false,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Students'
      }
    },
    {
      name: 'admin-course-queue',
      path: 'course-queue',
      component: () => import('../views/admin/courses/queue'),
      meta: {
        icon: 'fa-message',
        requiredRoles: ['admin', 'manager', 'employee'],
        adminNavbar: false,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Queue'
      }
    },
    {
      name: 'admin-team',
      path: 'team',
      component: () => import('../views/admin/team/index.vue'),
      meta: {
        icon: 'person-drowning',
        requiredRoles: ['admin', 'manager'],
        adminNavbar: true,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Team'
      }
    },
    {
      name: 'admin-controls',
      path: 'settings',
      component: () => import('../views/admin/control/index.vue'),
      meta: {
        icon: 'gear',
        requiredRoles: ['admin', 'manager'],
        adminNavbar: true,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Control Center'
      }
    },
    {
      name: 'admin-support',
      path: 'support',
      component: () => import('../views/admin/support/index.vue'),
      meta: {
        icon: 'circle-info',
        requiredRoles: ['admin', 'manager', 'employee'],
        adminNavbar: true,
        requiresAuth: true,
        showOnNavbar: false,
        showNavbar: false,
        navbarTitle: 'Support'
      }
    }
  ]
}

export default routes
