<template>
  <div class="dialogAlertBox">
    <v-alert dismissible type="error" v-model="dialogAlerts.error.isOpen" border="top" transition="scroll-y-transition">
      {{ dialogAlerts.error.message }}
    </v-alert>
    <v-alert dismissible type="warning" v-model="dialogAlerts.warning.isOpen" border="top" transition="scroll-y-transition">
      {{ dialogAlerts.warning.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'DialogAlert',
  data: () => ({
    dialogAlerts: {
      error: {
        isOpen: false,
        message: String()
      },
      warning: {
        isOpen: false,
        message: String()
      }
    }
  }),
  methods: {
    doShowErrorDialog (message) {
      this.dialogAlerts.error.isOpen = true
      this.dialogAlerts.error.message = message
      setTimeout(() => {
        this.$nextTick(() => {
          this.dialogAlerts.error.isOpen = false
        })
      }, 10000)
    },
    doShowWarningDialog (message) {
      this.dialogAlerts.warning.isOpen = true
      this.dialogAlerts.warning.message = message
      setTimeout(() => {
        this.$nextTick(() => {
          this.dialogAlerts.warning.isOpen = false
        })
      }, 10000)
    }
  },
  mounted () {
    this.$root.$on('showError', this.doShowErrorDialog)
    this.$root.$on('showWarning', this.doShowWarningDialog)
  }
}
</script>

<style scoped>
.dialogAlertBox {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  z-index: 10000;
}
</style>
