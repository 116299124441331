const axios = require('axios').default
const store = require('@/store').default

const http = axios.create({
  baseURL: 'https://sea2sea-api.unixity.co',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

http.defaults.headers.common.Authorization = `Bearer ${store.getters.doGetToken}`

export default http
