const services = {
  user: require('@/services/user').default,
  role: require('@/services/role').default,
  session: require('@/services/session').default,
  course: require('@/services/course').default,
  trip: require('@/services/trip').default,
  stats: require('@/services/stats').default,
  teamMember: require('@/services/teamMember').default,
  controllerTip: require('@/services/controllerTip').default,
  padi: require('@/services/padi').default,
  media: require('@/services/media').default,
  checkout: require('@/services/checkout').default,
  transaction: require('@/services/transaction').default,
  contact: require('@/services/contact').default
}

export default services
