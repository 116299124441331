const Vue = require('vue').default
const Vuex = require('vuex')
const createPersistedState = require('vuex-persistedstate').default

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage
  })],
  state () {
    return {
      session: {
        uuid: null
      },
      user: {
        id: null,
        roleKey: null,
        firstName: null,
        lastName: null,
        fullName: null,
        email: null,
        phoneNumber: null,
        token: null,
        firstSignIn: null,
        tempPassword: null
      },
      cart: {
        groups: []
      }
    }
  },
  getters: {
    doGetUser (state) {
      return state.user
    },
    doGetToken (state) {
      return state.user.token
    },
    doGetRole (state) {
      return state.user.roleKey
    },
    doGetFirstSignIn (state) {
      return state.user.firstSignIn
    },
    doGetTempPassword (state) {
      return state.user.tempPassword
    },
    doGetSession (state) {
      return state.session.uuid
    }
  },
  mutations: {
    doSetSession (state, uuid) {
      state.session.uuid = uuid
    },
    doSetUser (state, data) {
      state.user.id = data.id
      state.user.roleKey = data.roleKey
      state.user.firstName = data.firstName
      state.user.lastName = data.lastName
      state.user.fullName = data.fullName
      state.user.email = data.email
      state.user.phoneNumber = data.phoneNumber
      state.user.token = data.token
      state.user.firstSignIn = data.firstSignIn
      state.user.tempPassword = data.tempPassword

      Vue.prototype.$http.defaults.headers.common.Authorization = `Bearer ${state.user.token}`
    },
    doSetRole (state, role) {
      state.user.roleKey = role
    },
    doSetFirstSignIn (state, complete) {
      state.user.firstSignIn = complete
    },
    doSetTempPassword (state, complete) {
      state.user.tempPassword = complete
    },
    doResetUser (state) {
      state.user = {
        id: null,
        roleKey: null,
        firstName: null,
        lastName: null,
        fullName: null,
        email: null,
        phoneNumber: null,
        token: null,
        firstSignIn: null,
        tempPassword: null
      }

      Vue.prototype.$http.defaults.headers.common.Authorization = null

      localStorage.clear()
      sessionStorage.clear()
    },
    doAddProductGroup (state, productsGroupObject) {
      const type = productsGroupObject.type
      const productId = productsGroupObject.id
      const setProducts = productsGroupObject.products

      if (type === 'course') {
        const index = state.cart.groups.findIndex(({ id, type }) => id === productId && type === 'course')

        if (index >= 0) {
          state.cart.groups[index].products = [...state.cart.groups[index].products, ...setProducts]
        } else {
          state.cart.groups.push(productsGroupObject)
        }
      } else {
        state.cart.groups.push(productsGroupObject)
      }
    },
    doSetProductsInProductGroup (state, productsGroupObject) {
      const productGroupUuid = productsGroupObject.uuid
      const products = productsGroupObject.products

      const index = state.cart.groups.findIndex(({ uuid }) => uuid === productGroupUuid)

      state.cart.groups[index].products = products
    },
    doRemoveProductGroup (state, productGroupUuid) {
      state.cart.groups = state.cart.groups.filter(({ uuid }) => uuid !== productGroupUuid)
    },
    doResetProductGroupsCart (state) {
      state.cart = {
        groups: []
      }
    }
  },
  actions: {
    doAuthenticateOTP ({ commit }, auth) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$services.user.auth.doAuthenticateOTP(auth.phoneNumber, auth.verificationCode).then(data => {
          commit('doSetUser', data)

          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doAuthenticateEmail ({ commit }, auth) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$services.user.auth.doAuthenticateEmail(auth.email, auth.password).then(data => {
          commit('doSetUser', data)

          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doVerifyRole ({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$services.user.profile.doRead().then(data => {
          commit('doSetRole', data.roleKey)

          resolve(data.roleKey)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doCreateSession ({ commit }) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$services.session.doGenerate().then(data => {
          commit('doSetSession', data.uuid)

          resolve(data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    doAddProductGroup ({ commit }, productGroup) {
      return new Promise((resolve) => {
        commit('doAddProductGroup', productGroup)

        resolve(productGroup)
      })
    },
    doRemoveProductGroup ({ commit }, productGroupUuid) {
      return new Promise((resolve) => {
        commit('doRemoveProductGroup', productGroupUuid)

        resolve(productGroupUuid)
      })
    },
    doSetProductsInProductGroup ({ commit }, productsGroupObject) {
      return new Promise((resolve) => {
        commit('doSetProductsInProductGroup', productsGroupObject)

        resolve(productsGroupObject)
      })
    },
    doResetProductGroupsCart ({ commit }) {
      return new Promise((resolve) => {
        commit('doResetProductGroupsCart')

        resolve()
      })
    }
  }
})
